import React, { Dispatch, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Avatar,
  Button,
  FormControl,
  Grid,
  Link,
  TextField,
  ThemeProvider,
  Typography
} from '@mui/material'

import FacebookLogin from '../../../components/facebookLogin'
import GoogleLogin from '../../../components/googleLogin'
import { AppState, ContentLabels } from '../../../config/types'
import { DispatchAction } from '../../../config/types/contextTypes'
import { SocialNetworks } from '../../../config/types/socialNetworksTypes'
import { login } from '../../../context/actions/loginActions'
import { useStateValue } from '../../../context/store'
import { themeCharofil } from '../../../styles/styles'
import { useStyles } from '../useStyles'

interface LoginFormProps {
  labels: ContentLabels
  socialNetworks: SocialNetworks[]
  onForgotPassword: () => void
  onCreateAccount: () => void
}

const LoginForm: React.FC<LoginFormProps> = ({
  labels,
  socialNetworks,
  onForgotPassword,
  onCreateAccount,
}) => {
  const navigate = useNavigate()
  const { dispatch }: { state: AppState; dispatch: Dispatch<DispatchAction> } = useStateValue()

  const classes = useStyles()

  const [userName, setUserName] = useState<string>('')
  const [userPassword, setUserPassword] = useState<string>('')

  const validateUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value.trim())
  }

  const validateUserPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserPassword(event.target.value.trim())
  }

  const onEnterKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key.toLocaleLowerCase() === 'enter') handleAuth()
  }

  const handleAuth = async () => {
    if (!userName || !userPassword) return
    await login(dispatch, { userName, userPassword })
  }

  // const handleChangeLang = async (lang: string) => {
  //   console.log(lang)
  // }

  return (
    <Grid container direction="column" className={classes.cardContainer}>
      <Grid container style={{ padding: 10 }}>
        <Grid item xs={12} style={{ padding: 20 }}>
          {/* Español | English | Portuguese */}
        </Grid>
        <Grid item xs={12} style={{ padding: 20 }}>
          <Grid
            item
            xs={12}
            display={'flex'}
            alignItems="center"
            justifyContent="center"
            style={{ padding: 10 }}
          >
            <Avatar sx={{ bgcolor: '#E90031' }}></Avatar>
          </Grid>
          <Grid item xs={12} display={'flex'} alignItems="center" justifyContent="center">
            <Typography component="h1" variant="h5">
              {labels.login.loginTitle}
            </Typography>
          </Grid>

          <Grid container direction="column">
            <Grid item xs={12}>
              <FormControl fullWidth>
                <ThemeProvider theme={themeCharofil}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    required
                    id="user"
                    name="user"
                    label={labels.login.loginUser}
                    value={userName}
                    onChange={validateUserName}
                    onKeyUp={onEnterKeyPress}
                    inputProps={{
                      style: { textAlign: 'center' },
                    }}
                  />
                </ThemeProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <ThemeProvider theme={themeCharofil}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    required
                    id="password"
                    name="password"
                    label={labels.login.loginPassPlaceholder}
                    type="password"
                    value={userPassword}
                    onChange={validateUserPassword}
                    onKeyUp={onEnterKeyPress}
                    inputProps={{
                      style: { textAlign: 'center' },
                    }}
                  />
                </ThemeProvider>
              </FormControl>
            </Grid>
          </Grid>

          {/* <Grid item justifyItems="center">
            <Typography component="h5" variant="body2" color={'error'}>
              Por favor, intenta nuevamente.
            </Typography>
          </Grid> */}

          <Grid style={{ width: '100%', paddingTop: 20, paddingBottom: 20 }}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              onClick={handleAuth}
              style={{ backgroundColor: '#BE0A15' }}
            >
              {labels.login.loginBtnContinue}
            </Button>
          </Grid>

          <Grid container direction="column">
            <Grid item>
              <Link
                variant="body2"
                onClick={onForgotPassword}
                style={{ cursor: 'pointer' }}
                color={'error'}
              >
                {labels.login.loginForgotPass}
              </Link>
            </Grid>
            <Grid item>
              <Link
                variant="body2"
                onClick={onCreateAccount}
                style={{ cursor: 'pointer' }}
                color={'error'}
              >
                {labels.login.loginCreateAccount}
              </Link>
            </Grid>
          </Grid>
        </Grid>

        {socialNetworks.length > 0 ? (
          <Grid item xs={12} style={{ padding: 20 }}>
            <Grid container direction="column" justifyContent="space-between" alignItems="center">
              <div>
                <Grid item>{labels.login.loginSocialNetworks}</Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {socialNetworks.map((social) =>
                      social.nombre === 'Facebook' && social.appID ? (
                        <FacebookLogin key={'FB'} />
                      ) : social.nombre === 'Google' && social.appID ? (
                        <GoogleLogin key={'Google'} />
                      ) : social.nombre === 'Apple' && social.appID ? null : (
                        <Grid item justifyContent="center" className={classes.errorMsgGrid}>
                          No se logró cargar la opción para {social.nombre}
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        ) : (
          ''
        )}

        <Grid item xs={12}>
          <Grid item>
            <Link
              variant="body2"
              onClick={() => navigate('/terminoscondiciones')}
              style={{ cursor: 'pointer' }}
              color={'error'}
            >
              Terminos y condiciones
            </Link>
          </Grid>
          <Grid item>
            <Link
              variant="body2"
              onClick={() => navigate('/avisodeprivacidad')}
              style={{ cursor: 'pointer' }}
              color={'error'}
            >
              Aviso de privacidad
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LoginForm
