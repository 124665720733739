export const DOMAIN = 'https://www.cuantificador.charofil-apps.com'
export const API_DOMAIN = `${DOMAIN}`

// export const DOMAIN = 'http://192.168.100.189'
// export const API_DOMAIN = `${DOMAIN}:4000`

export const ENDPOINT = `${DOMAIN}`
export const API_ENDPOINT = `${API_DOMAIN}/api`

export const API_VERSION = 'v1'

export const DEVICE_BROWSER = 'Web'
export const DEVICE_IOS = 'Web IOS'
export const DEVICE_ANDROID = 'Web Android'

// API Routes
export const ROUTE_STATUS = 'status'
export const ROUTE_LOGIN = 'login'
export const ROUTE_FACEBOOK_LOGIN = 'fbLogin'
export const ROUTE_GOOGLE_LOGIN = 'googleLogin'
export const ROUTE_LOGIN_CONTENT = 'loginContent'
export const ROUTE_CONTENT = 'getContent'
export const ROUTE_GET_PRODUCTS = 'getProducts'
export const ROUTE_GET_PRODUCT_COMPLEMENT = 'getProductCompl'
export const ROUTE_UPDATE_USER = 'updateUser'
export const ROUTE_USER_HISTORY = 'history'
export const ROUTE_SAVE_LIST = 'history'
export const ROUTE_EXPORT_EXCEL = 'export'
export const ROUTE_CALCULATE = 'calculate'
export const ROUTE_SESSION = 'session'
export const ROUTE_CREATE_ACCOUNT = 'adminUsers'
export const ROUTE_RECOVERY_PASSWORD = 'sendEmail'

export const SESSION_VALIDATE = '/session'
export const SESSION_GUEST_LOGIN = '/gLogin'
export const SESSION_LOGOUT = '/logout'

export const ENCRYPTION_KEY = 'B3p46vlRDhkdnnmJElFBf7A7LnmThr'

export const GOOGLE_LOGIN_CLIENT_ID =
  '1072470886399-8jf8tviv4gjunocs6a1hd2qhpc9tri44.apps.googleusercontent.com'
